import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { User } from "../types/type";

interface Account extends AccountInfo {
  idTokenClaims: {
    aud: string;
    auth_time: number;
    family_name: string;
    given_name: string;
    emails: string[];
    iss: string;
    nbf: number;
    nonce: string;
    sub: string;
    tfp: string;
    ver: string;
  };
}

// user 情報
const userInstance: User = {
  sub: "",
  familyName: "",
  givenName: "",
  email: "",
  token: "",
};

export const useUser = (): User | null | undefined => {
  const { accounts, instance } = useMsal();
  const [user, setUser] = useState<User>(userInstance);
  // tokenを取得するかどうか
  const [acquireInProgress, setAcquireInProgress] = useState<boolean>(false);
  useEffect(() => {
    if (!user.token) {
      if (accounts.length > 0) {
        const account = accounts[0] as Account;
        const request = {
          ...loginRequest,
          account: accounts[0],
        };

        userInstance.sub = account.idTokenClaims?.sub;
        userInstance.familyName = account.idTokenClaims?.family_name;
        userInstance.givenName = account.idTokenClaims?.given_name;
        userInstance.email = account.idTokenClaims?.emails[0];

        const acquireToken = () => {
          if (acquireInProgress) return;

          setAcquireInProgress(true);

          // Silently acquires an access token which is then attached to a request for Microsoft Graph data
          instance
            .acquireTokenSilent(request)
            .then((response: any) => {
              userInstance.token = response.idToken;
              setUser(userInstance);
              setAcquireInProgress(false);
            })
            .catch((e) => {
              instance.acquireTokenPopup(request).then((response: any) => {
                userInstance.token = response.idToken;
                setUser(userInstance);
                setAcquireInProgress(false);
              });
            });
        };

        // get azure idToken
        acquireToken();

        // 3600s=30min
        const ACQUIRE_TOKEN_INTERVAL = Number(
          process.env.REACT_APP_ACQUIRE_TOKEN_INTERVAL
        );
        setInterval(() => {
          acquireToken();
        }, ACQUIRE_TOKEN_INTERVAL * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquireInProgress, user]);

  return user;
};
