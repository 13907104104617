import { Box, Container, experimentalStyled } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../libs/theme";
import { Header } from "../header/Header";
import { SideMenu } from "../sideMenu/SideMenu";

type Props = {
  children: React.ReactElement;
};

const MainWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));

const PageWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
}));

export const DefaultLayout: React.FC<Props> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <MainWrapper sx={{ backgroundColor: theme.gray100 }}>
      <Header
        sx={{
          paddingLeft: isSidebarOpen ? "265px" : "",
          backgroundColor: theme.gray100,
          color: theme.gray800,
        }}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        customClass={undefined}
        position={undefined}
      />
      <SideMenu
        isSidebarOpen={isSidebarOpen}
        onSidebarClose={() => setIsSidebarOpen(false)}
      />
      <PageWrapper>
        <Container
          maxWidth={false}
          sx={{
            paddingLeft: isSidebarOpen ? "280px!important" : "",
          }}
        >
          <Box sx={{ minHeight: "calc(100vh - 170px)", padding: "0 32px" }}>
            {children}
          </Box>
        </Container>
      </PageWrapper>
    </MainWrapper>
  );
};
