import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import FeatherIcon from "feather-icons-react";
import React from "react";
import { ProfileDD } from "./ProfileDD";
type Props = {
  sx: any;
  customClass: any;
  position: any;
  toggleSidebar: any;
};

export const Header: React.FC<Props> = ({
  sx,
  customClass,
  toggleSidebar,
  position,
}) => {
  return (
    <AppBar sx={sx} position={position} elevation={0} className={customClass}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          size="large"
          aria-label="menu"
          onClick={toggleSidebar}
          sx={{
            display: "flex",
          }}
        >
          <FeatherIcon icon="menu" width="24" height="24" />
        </IconButton>
        <ProfileDD />
      </Toolbar>
    </AppBar>
  );
};
