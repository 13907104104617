import { styled, TextField } from "@mui/material";
import { theme } from "../../../libs/theme";

export const StyledTextField = styled(TextField)(() => ({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  color: theme.gray800,
  "& .MuiInput-underline:after": {
    borderColor: `${theme.defaultColor}!important`,
  },
}));

export const StyledMultiTextField = styled(TextField)(() => ({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  color: theme.gray800,
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: `${theme.defaultColor}!important`,
    },
  },
}));
