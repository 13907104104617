import { Card, CardContent } from "@mui/material";
import React from "react";
import { theme } from "../../libs/theme";

type Props = {
  cardPadding?: string;
  children: React.ReactElement;
  cardContentType?: Object; //sxのcssをオブジェクトでもたせる
  overFlowVisible?: boolean;
};

export const BaseCard: React.FC<Props> = ({
  cardPadding = "16px 24px",
  children,
  cardContentType = {},
  overFlowVisible = false,
}) => {
  return (
    <Card
      sx={{
        borderRadius: "24px",
        padding: cardPadding,
        boxShadow: `0px 7px 30px ${theme.cardShadow}`,
        overflow: overFlowVisible ? "visible" : "auto",
        marginBottom: "50px",
        position: "relative",
      }}
    >
      <CardContent sx={cardContentType}>{children}</CardContent>
    </Card>
  );
};
