export const theme = {
  defaultColor: "#317ae1",
  subColor: "#ff8c8c",
  gray100: "#fbfbfb",
  gray300: "#ddd",
  gray500: "#999",
  gray700: "#616161",
  gray750: "#555",
  gray800: "#333",
  white: "#fff",
  red: "red",
  winered: "#E81E42",
  pink: "#F178B6",
  loginpink: "#FF8C8C",
  lightblue: {
    main: "#03c9d7",
    light: "#03c9d74d",
  },
  lightorange: {
    main: "#fb9678",
    light: "#fb96784D",
  },
  purple: {
    main: "#bd99c6",
    light: "#bd99c64d",
  },
  gradOrange: "linear-gradient(to bottom right, #FE5196, #F77062)",
  cardShadow: "rgba(90, 114, 123, 0.11)",
};
