import { Box, createTheme, ThemeProvider } from "@mui/material";
import { PaginatedItems } from "./PagenationItems";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8C8C",
    },
  },
});

type Props = {
  allPage: number;
  pageNum: number;
  updateAnalysisData: (newPage: number) => void;
};

export const Pagenation: React.FC<Props> = ({
  allPage,
  pageNum,
  updateAnalysisData,
}) => {
  return (
    <Box sx={{ mt: "2.8%" }}>
      <ThemeProvider theme={theme}>
        <PaginatedItems
          allPage={allPage}
          page={pageNum}
          size={"large"}
          color={"primary"}
          onChange={updateAnalysisData}
        />
      </ThemeProvider>
    </Box>
  );
};
