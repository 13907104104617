import React, { useEffect } from "react";

type Props = {
  pageNum: number;
};

export const ScrollToTop: React.FC<Props> = ({ pageNum }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNum]);
  return null;
};
