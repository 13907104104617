import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../libs/theme";
import { textJa } from "../../locales/textJa";
import { analysisDataType } from "../../types/type";
import { StyledTextField } from "../common/styles/textField";

type Props = {
  form: {
    title: string;
    sitename: string;
  };
  setForm: React.Dispatch<
    React.SetStateAction<{
      title: string;
      sitename: string;
    }>
  >;
  apiData: analysisDataType;
};

export const TitleForm: React.FC<Props> = ({ form, setForm, apiData }) => {
  const [editForm, setEditForm] = useState<{
    title: string;
    sitename: string;
  }>(form);

  useEffect(() => {
    setEditForm({ title: apiData?.title, sitename: apiData?.site_name });
  }, [apiData]);

  useEffect(() => {
    setForm(editForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm]);

  return (
    <Box sx={{ mb: "40px" }}>
      <Stack spacing={3}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.subject}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.subject)}
          variant="standard"
          value={editForm.title}
          onChange={(e) =>
            setEditForm({ title: e.target.value, sitename: editForm.sitename })
          }
        />
      </Stack>
      <Stack spacing={3} sx={{ mt: "16px" }}>
        <Typography
          sx={{
            color: theme.defaultColor,
            marginBottom: "-24px!important",
            fontSize: "16px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          {textJa.common.sitename}
        </Typography>
        <StyledTextField
          id="name-basic"
          inputProps={{ maxLength: 60 }}
          placeholder={textJa.placeholder(textJa.common.subject)}
          variant="standard"
          value={editForm.sitename}
          onChange={(e) =>
            setEditForm({ title: editForm.title, sitename: e.target.value })
          }
        />
      </Stack>
    </Box>
  );
};
